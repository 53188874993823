<script>
import TablePage from '@/found/components/table_page';
import Form from '../form/form';

export default {
  extends: TablePage,
  components: {
    Form,
  },
  data() {
    return {};
  },
  mounted() {
    this.getConfigList('dms_complaint_table');
  },
  methods: {
    modalClick({ val, row }) {
      this.modalConfig.width = '50%';
      this.formConfig = {
        // ...val,
        formId: row.id,
        ...row,
      };
      this.openFull();
    },
  },
};
</script>

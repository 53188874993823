<template>
  <div class="home">
    <TablePage />
  </div>
</template>

<script>
import TablePage from './table/table';

export default {
  name: 'Complaint',
  components: {
    TablePage,
  },
};
</script>

<script>
import request from '@/found/utils/request';
import Form, { formCreate } from '@/found/components/form';

export default {
  extends: Form,
  components: {},
  data() {
    return {
      // form内组件
      rule: [],
    };
  },

  mounted() {
    this.getFormRule('dms_complaint_form');
  },

  methods: {
    formComplete() {
      this.getData();
    },
    getData() {
      request
        .get(`/dms/m/complaints/mDetailById/${this.formConfig.formId}`)
        .then((res) => {
          if (res.success) {
            this.setValue({
              customerName: res.result.customerInfo.customerName,
              customerCode: res.result.customerInfo.customerCode,
              registeredAddress: res.result.customerInfo.registeredAddress,
              contactName: res.result.customerInfo.contactName,
              contactPhone: res.result.customerInfo.contactPhone,
              channelName: res.result.customerInfo.channelName,
              createOrgName: res.result.customerInfo.mdmCustomerDockingVos.createOrgName,
              dockingName: res.result.customerInfo.mdmCustomerDockingVos.createName,
              dockingPhone: res.result.customerInfo.mdmCustomerDockingVos.contactPhone,
              complaintNo: res.result.complaintsVo.complaintNo,
              typeName: res.result.complaintsVo.typeName,
              updateDateAll: res.result.complaintsVo.updateDateAll,
              statusName: res.result.complaintsVo.statusName,
              title: res.result.complaintsVo.title,
              content: res.result.complaintsVo.content,
              files: res.result.complaintsVo.files,
            });
          }
        });
    },
    setRule(v) {
      const rowData = v;
      rowData.props = {
        ...rowData.props,
        disabled: true,
      };
      if (rowData.field === 'contentBack') {
        rowData.props = {
          ...rowData.props,
          disabled: false,
        };
      }
      if (rowData.field === 'filesBack') {
        rowData.props = {
          ...rowData.props,
          disabled: false,
        };
      }
      return rowData;
    },
  },
};
</script>
